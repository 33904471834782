import React from "react";
import { Helmet } from "react-helmet";
import BlogMain from "./BlogMain";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import Newsletter from "../../components/Common/Newsletter";
import ScrollToTop from "../../components/Common/ScrollTop";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SearchModal from "../../components/Layout/Header/SearchModal";

// Image
import favIcon from "../../assets/img/fav-orange.png";
import Logo from "../../assets/img/swardhara_new_logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

import bannerbg from "../../assets/img/breadcrumbs/2.jpg";

const Blog = () => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="blog"
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA"
      />

      {/* Blog Main */}
      <BlogMain />
      {/* Blog Main End */}

      <Footer
        footerClass="rs-footer home9-style main-home"
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default Blog;
