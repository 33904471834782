import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video';

const CategoriesSingle = (props) => {
    const { itemClass, title, image, iconImg, quantity, btnText, catLink, videoUrl } = props;
    const [isOpen, setIsOpen] = useState(false);
    let videoId = "";
    const openModal = () => setIsOpen(!isOpen);
    
    return (
        <div className="inner-column">
            <ModalVideo channel='youtube' isOpen={isOpen} videoId={videoUrl} onClose={() => { openModal(); }} />
            <div className={itemClass}>
            <div className="cate-images">
                <Link onClick={() => { openModal(); }}>
                    <img
                        src={image}
                        alt={title}
                    />
                </Link>
            </div>
            <div className="contents">
                <div className="content-wrap">
                    <h2 className="title">
                        <Link onClick={() => { openModal(); }}>{title}</Link>
                    </h2>
                    <span className="course-qnty">{quantity}</span>
                    {btnText ? <><div className="btn2"><Link onClick={() => { openModal(); }}>{btnText}</Link></div></> : ''}
                </div>
            </div>
        </div>
        </div>
        
    )
}

export default CategoriesSingle