import React, { useState, useEffect } from 'react';
import CategoriesSingle from "../../components/Categories/CategoriesSingle";

import lineImg from "../../assets/img/line.png";

import homeData from "../../sample-data/course-data/home.json";

const Categories = () => {
  const [creatorVideos, setCreatorVideos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      console.log('result',homeData);
      if(homeData.response && homeData.response.videoOfCreator) {
        setCreatorVideos(homeData.response.videoOfCreator);
      }
    };
    fetchData();
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  let videoId = "";
  const openModal = (videoUrl) => {
    videoId = videoUrl;
    setIsOpen(!isOpen);
  }
  return (
    <div className="rs-categories home-style14 pt-20 pb-50 md-pt-80 md-pb-80">
      <div className="container">
        <div className="sec-title6 text-center mb-40">
          <div className="img-part mb-10">
            <img src={lineImg} alt="" />
          </div>
          <h2 className="title">Our Content Creators</h2>
        </div>
        <div className="row">
          {creatorVideos.map(item => (
            <div className="col-lg-4 col-md-6 mb-30">
              <CategoriesSingle
                itemClass="categories-items"
                image={item.image}
                title={item.video_name}
                quantity={item.video_description}
                btnText="Play"
                videoUrl ={item.video_url}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
