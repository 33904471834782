import React, { useState, useEffect } from "react";
import axios from 'axios';
import CourseSidebar from "./CourseSidebar";
import CourseDetailsTab from "./CourseDetailsTab";
import { useParams } from "react-router-dom"
import courseData from '../../sample-data/course-data/course-detail.json';
const CourseDetailsPart = () => {
  let { id } = useParams();
  const [courseItemData, setCourseItemData] = useState([]);

  useEffect(() => {
      const fetchData = async () => {
          const result = await axios.get('https://www.swargandhrva.com/user/v2/home');
          setCourseItemData(result.response.newPremiumVideo);
      };
      fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="intro-section gray-bg pt-94 pb-100 md-pt-80 md-pb-80 loaded">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-8 md-mb-50">
              <CourseDetailsTab 
              courseData={courseData[id]}/>
            </div>
            <div className="video-column col-lg-4">
              <CourseSidebar 
              videoId = {courseData[id].videos[0].videoUrl}
              videoImage = {courseData[id].videos[0].videoImage} 
              lectureCount ={courseData[id].videos.length}
              cost ={courseData[id].tutorial_cost}/>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseDetailsPart;
