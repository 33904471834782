import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';

// Image
import videoImg from '../../assets/img/about/about-video-bg2.png';

const CourseSidebar = (props) => {
    const { videoId, videoImage , lectureCount, cost } = props;
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);
    const videoImgUrl=""
    return (
        <div className="inner-column">
            <ModalVideo channel='youtube' isOpen={isOpen} videoId={videoId} onClose={() => { openModal(); }} />
            <div className="intro-video media-icon orange-color2">
                <img className="video-img" src={videoImg} alt="Video Image" />
                <Link className="popup-videos" onClick={() => { openModal(); }} >
                    <i className="fa fa-play"></i>
                </Link>
                <h4>Preview this course</h4>
            </div>
            <div className="course-features-info">
                <ul>
                    <li className="lectures-feature">
                        <i className="fa fa-files-o"></i>
                        <span className="label">Lectures</span>
                        <span className="value">{lectureCount}</span>
                    </li>
                </ul>
            </div>                
            <div className="btn-part">
                <a href="#" className="btn readon2 orange">$ {cost}</a>
                <a href="#" className="btn readon2 orange-transparent">Buy Now</a>
            </div>
        </div>
    );
}

export default CourseSidebar;