import React from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import CourseDetailsMain from "./CourseDetailsMain";

// Image
import bannerbg from "../../assets/img/breadcrumbs/2.jpg";

const CourseSingle = () => {
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="course"
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        emailAddress="support@website.com"
        Location="374 William S Canning Blvd, MA 2721, USA "
      />

      {/* Course Details Main */}
      <CourseDetailsMain />
      {/* Course Details Main */}

      <Footer
        footerClass="rs-footer home9-style main-home"
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default CourseSingle;
