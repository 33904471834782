import React from 'react';
import { Route, BrowserRouter as Router, Switch, withRouter } from 'react-router-dom';

//Custom Components

import Home from '../app-screens/home';
import About from '../app-screens/about';
import Course from '../app-screens/course';
import CourseSingle from '../app-screens/course-single';
import Checkout from '../app-screens/checkout';
import MyAccount from '../app-screens/my-account';
import Login from '../app-screens/login';
import Register from '../app-screens/register';
import Blog from '../app-screens/blog';
import Contact from '../app-screens/contact';
import Error from '../app-screens/404';
import LoadTop from '../components/Common/ScrollTop/LoadTop'


const App = () => {
    return (
        <div className='App'>
            <Router>
                <LoadTop />
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/home" component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/course" exact component={Course} />
                    <Route path="/course/detail/:id" component={CourseSingle} />
                    <Route path="/checkout" component={Checkout} />
                    <Route path="/shop/my-account" component={MyAccount} />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/blog" exact component={Blog} />
                    <Route path="/contact" component={Contact} />
                    {/* <Route path="/course-categories" component={CourseCategoryPage} /> */}
                    {/* <Route path="/event" component={Event} /> */}
                    {/* <Route path="/shop" exact component={Shop} /> */}
                    {/* <Route path="/shop/shop-single" component={ShopSingle} /> */}
                    {/* <Route path="/shop/cart" component={Cart} /> */}
                    {/* <Route path="/faq" component={Faq} /> */}
                    {/* <Route path="/blog/blog-left-sidebar" component={BlogLeft} />
                    <Route path="/blog/blog-right-sidebar" component={BlogRight} />
                    <Route path="/blog/single-post-left-sidebar" component={SinglePostLeftSidebar} />
                    <Route path="/blog/single-post-right-sidebar" component={SinglePostRightSidebar} />
                    <Route path="/blog/single-post-full-width" component={SinglePostFullWidth} /> */}
                    <Route component={Error} />
                </Switch>
            </Router>

        </div>
    );
}

export default App;
