import React from 'react';
import { Link } from 'react-router-dom';

const CourseSingleSeven = (props) => {
    const { courseClass, courseImg, courseTitle, coursePrice , courseId } = props;
    const coursePath=`/course/detail/${courseId}`
    return (
        <div className={courseClass ? courseClass : 'courses-item'}>
            <Link to={coursePath}>
                <div className="img-part">
                    <img
                        src={courseImg}
                        alt={courseTitle}
                    />
                </div>
                <div className="content-part">
                    <ul className="meta-part">
                        <li><span className="price">{coursePrice ? coursePrice : '$40.00'}</span></li>
                    </ul>
                    <h3 className="title">{courseTitle ? courseTitle : 'Artificial Intelligence Fundamental Startup Learn'}</h3>
                </div>
            </Link>
        </div>
    )
}

export default CourseSingleSeven