import React, { useState, useEffect } from 'react';
import axios from 'axios';

import CourseSingleTwo from '../../components/Courses/CourseSingleTwo';
import homeData from "../../sample-data/course-data/home.json";

const Courses = () => {

    // const courseListData = [];
    const [courseListData, setCourseListData] = useState([]);

    const [courses, setCourses] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            if(homeData.response && homeData.response.newPremiumVideo) {
                setCourses(homeData.response.newPremiumVideo);
            }
        };
        fetchData();
    }, []);
    courses.forEach((course) => {
        courseListData.push(<div className="col-lg-4 col-md-6 mb-30">
        <CourseSingleTwo
            courseClass="courses-item"
            courseImg={course.preview_image}
            courseTitle={course.name}
            coursePrice={course.cost}
            userCount={course.videos}
            courseId={course.id}
            courseCategory="Swardhara"
            btnText="Checkout"
        />
    </div>)
    })

    return (
        <div className="rs-popular-courses style4 orange-style pt-110 pb-120 md-pt-80 md-pb-80">
            <div className="container">
                <div className="row">
                    {courseListData}
                </div>
            </div>
        </div>
    );
}

export default Courses;