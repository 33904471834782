import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

const CurriculumPart = (props) => {
  const { title , videos, description } = props;
  const [isOpen ,setIsOpen] = useState(false);
  const [playingVideoId ,updateVideoId] = useState("");
  const openModal = () => {
    setIsOpen(!isOpen);
  }
  const videoList = []
  
  videos.forEach((video) => {
    videoList.push(<div className="content">
    <div className="clearfix">
      <div className="pull-left">
          <Link
            className="popup-videos play-icon"
            onClick={() => {
              updateVideoId(video.videoUrl);
              openModal();
            }}
          >
          <i className="fa fa-play"></i>{video.videoTitle}
        </Link>
      </div>
    </div>
  </div>)
  })

  return (
    <div className="content">
      <Accordion className="accordion-box" preExpanded={"a"}>
        <AccordionItem className="accordion-item" uuid="a">
          <AccordionItemHeading>
            <AccordionItemButton>
              <button>{title}</button>
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="card-body acc-content current">
            <div className="content white-bg pt-30">
                <div className="course-overview">
                    <div className="inner-box">
                        <p>{description}</p>
                    </div>
                </div>
            </div>
            <ModalVideo
              channel="youtube"
              isOpen={isOpen}
              videoId={playingVideoId}
              onClose={() => {
                updateVideoId("")
                openModal();
              }}
            />
            {videoList}
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CurriculumPart;
