import React from 'react';
import Header from '../../components/Layout/Header/Header';
import Footer from '../../components/Layout/Footer/Footer';
import OffWrap from '../../components/Layout/Header/OffWrap';
import SearchModal from '../../components/Layout/Header/SearchModal';
import Newsletter from '../../components/Common/Newsletter';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import CourseMain from './CourseMain';

// Image
import Logo from '../../assets/img/logo/dark-logo.png';
import footerLogo from '../../assets/img/logo/lite-logo.png';

import bannerbg from '../../assets/img/breadcrumbs/2.jpg';

const CourseFour = () => {

    return (
        <React.Fragment>
            <OffWrap />
            <Header
                parentMenu='course'
                CanvasClass="right_menu_togle hidden-md"
                headerClass="full-width-header header-style1 home8-style4"
                TopBarClass="topbar-area home8-topbar"
                emailAddress='support@website.com'
                Location='374 William S Canning Blvd, MA 2721, USA '
            />

            
            {/* Course Main */}
            <CourseMain />
            {/* Course Main */}

            

            <Footer
                footerClass="rs-footer home9-style main-home"
            />
            <SearchModal />
        </React.Fragment>
    );
}

export default CourseFour;