import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CourseSingleSeven from "../../components/Courses/CourseSingleSeven";
import lineImg from "../../assets/img/line.png";

import homeData from "../../sample-data/course-data/home.json";

const Courses = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      console.log('result',homeData);
      if(homeData.response && homeData.response.newPremiumVideo) {
        setCourses(homeData.response.newPremiumVideo);
      }
    };
    fetchData();
  }, []);
  const tabStyle = "gridFilter style2 text-center mb-30";

  return (
    <div className="rs-popular-courses style1 orange-style modify1 pt-100 pb-20 md-pt-80 md-pb-72">
      <div className="container">
        <div className="sec-title6 text-center mb-40">
          <div className="img-part mb-10">
            <img src={lineImg} alt="" />
          </div>
          <h2 className="title">Explore Our Courses</h2>
        </div>
        <Tabs>
          <TabList className={tabStyle}></TabList>

          <TabPanel>
            <div className="row ">
              {courses.map(item => (
                <div className="col-lg-4 col-md-6">
                  <CourseSingleSeven
                    courseClass="courses-item mb-30"
                    courseImg={item.preview_image}
                    courseTitle={item.info}
                    coursePrice={`₹ ${item.cost}`}
                    courseId = {item.id}
                  />
                </div>
              ))}
            </div>
          </TabPanel>

        </Tabs>
        <div className="sec-title5 text-center mt-40 md-mt-6">
          <div className="description title-color">
            Start Learning With Our Trending Courses.
            <span>
              <Link to="/course">
                {" "}
                View All courses<i className="flaticon-right-arrow"></i>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Courses;
