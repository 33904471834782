import React from 'react';
import { Link } from 'react-router-dom';

const CourseSingleTwo = (props) => {
    const { courseClass, courseImg, courseTitle, catLink, coursePrice, courseCategory, userCount, userRating , courseId} = props;
    const path ="/course/detail/"+courseId
    return (
        <div className={courseClass ? courseClass : 'courses-item'}>
            <Link to={path}>
                <div className="img-part">
                    <img
                        src={courseImg}
                        alt={courseTitle}
                    />
                </div>
                <div className="content-part">
                    <ul className="meta-part">
                        <li><span className="price">{coursePrice ? coursePrice : '55.00'}</span></li>
                        <li><Link className="categorie" to={catLink ? catLink : 'course-categories'}>{courseCategory ? courseCategory : 'Web Development'}</Link></li>
                    </ul>
                    <h3 className="title"><Link to={path}>{courseTitle ? courseTitle : 'Become a PHP Master and Make Money Fast'}</Link></h3>
                    <div className="bottom-part">
                        <div className="info-meta">
                            <ul>
                                <li className="user"><i className="fa fa-files-o"></i> {userCount ? userCount : '245'}</li>
                                
                            </ul>
                        </div>
                        <div className="btn-part">
                            <Link to={path}>
                                {props.btnText}<i className="flaticon-right-arrow"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default CourseSingleTwo