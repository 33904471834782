import React, { useState, useEffect } from 'react';
import SinglePostSidebar from './SinglePostSidebar';
import SinglePostTwo from '../../components/Blog/SinglePostTwo';

import blogImg1 from '../../assets/img/blog/inner/1.jpg';
import homeData from "../../sample-data/course-data/home.json";

const BlogMain = () => {
    const [creatorVideo, setCreatorVideo] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        if(homeData.response && homeData.response.videoOfCreator) {
            setCreatorVideo(homeData.response.videoOfCreator);
        }
      };
      fetchData();
    }, []);
    return (
        <div className="rs-inner-blog orange-style pt-100 pb-100 md-pt-70 md-pb-80">
            <div className="container">
                <div className="row">
                    {/* <div className="col-lg-4 col-md-12 order-last">
                        <div className="widget-area">
                            <SinglePostSidebar />
                        </div>
                    </div> */}

                    <div className="col-lg-12 pr-50 md-pr-16">
                        <div className='row'>
                            {creatorVideo.map(item => (
                                <div className='col-lg-12 mb-70 md-mb-50'>
                                    <SinglePostTwo
                                        blogImage={item.image}
                                        blogTitle={item.video_name}
                                        blogDesc='Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam... '
                                        blogButtonClass='blog-button'
                                        blogButtonText='PLAY'
                                        blogVideoId={item.video_url}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogMain;