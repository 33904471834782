import React from "react";
import { Link, useLocation } from "react-router-dom";

const MenuItems = (props) => {
  const { parentMenu, secondParentMenu } = props;

  const location = useLocation();

  return (
    <React.Fragment>
      <li
        className={
          parentMenu === "home"
            ? "rs-mega-menu current-menu-item"
            : "rs-mega-menu "
        }
      >
        <Link to="/">Home</Link>
      </li>
      <li className={parentMenu === "about" ? "current-menu-item" : ""}>
        <Link to="/about">About</Link>
      </li>
      <li className={parentMenu === "course" ? "current-menu-item" : ""}>
        <Link to="/course" as="/course">
          Courses
        </Link>
      </li>
      <li className={parentMenu === "blog" ? "current-menu-item" : ""}>
        <Link to="/blog">Blog</Link>
      </li>
      <li className={parentMenu === "contact" ? "current-menu-item" : ""}>
        <Link to="/contact">Contact</Link>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
