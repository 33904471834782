import React, { useState } from "react";
import { Link } from 'react-router-dom';
import ModalVideo from "react-modal-video";

const SinglePostTwo = (props) => {
    const [playingVideoId ,updateVideoId] = useState("");
    const [isOpen ,setIsOpen] = useState(false);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    const { blogClass, blogImage, blogTitle, blogButtonClass, blogButtonText, ImgOrder, blogVideoId } = props;
    return (
        <div className={blogClass ? blogClass : 'row align-items-center no-gutter white-bg blog-item mb-30'}>
            <div className={ImgOrder == 'last' ? 'col-md-6 order-last' : 'col-md-6'}>
                <div className="image-part">
                    <Link to="/blog/single-post-right-sidebar">
                        <img
                            src={blogImage}
                            alt={blogTitle}
                        />
                    </Link>
                </div>
            </div>
            <div className="col-md-6">
                <div className="blog-content">
                    <h3 className="title">
                        <Link >
                            {blogTitle ? blogTitle : 'Open Source Job Report Show More Openings Fewer '}
                        </Link>
                    </h3>
                    <div className="btn-part">
                        <Link 
                        className={blogButtonClass ? blogButtonClass : 'readon-arrow'}
                        onClick={() => {
                            updateVideoId(blogVideoId);
                            openModal();
                        }}>
                            {blogButtonText ? blogButtonText : 'Read More'}
                        </Link>
                    </div>
                </div>
            </div>
            <ModalVideo
              channel="youtube"
              isOpen={isOpen}
              videoId={playingVideoId}
              onClose={() => {
                updateVideoId("")
                openModal();
              }}
            />
        </div>

    )
}

export default SinglePostTwo