import React from "react";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import AboutMain from "./AboutMain";

// Image
import Logo from "../../assets/img/swardhara_new_logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";

const About = () => {
  return (
    <React.Fragment>
      <OffWrap />
      <Header
        parentMenu="about"
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
      />

      {/* About Main */}
      <AboutMain />
      {/* About Main */}

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-70"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
      />
      <SearchModal />
    </React.Fragment>
  );
};

export default About;
