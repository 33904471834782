import React from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";

import footerLogo1 from "../../../assets/img/swardhara_new_logo.png";
// import postImg1 from "../../../assets/img/blog/post1.jpg";
// import postImg2 from "../../../assets/img/blog/post2.jpg";

const Footer = (props) => {
  const { footerLogo, footerClass, footerTopClass } = props;
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className={`footer-top ${footerTopClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <div className="footer-logo mb-30">
                <Link to="/" as="/">
                  <img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" />
                </Link>
              </div>
              <div className="textwidget pr-60 md-pr-14">
                <p>
                    Learn Music curated by people who believe music is emotion and Life
                </p>
              </div>
              {/* <ul className="footer_social">
                <li>
                  <a href="#">
                    <i className="fa fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-pinterest"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-google-plus"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul> */}
            </div>
            <div className="col-lg-3 col-md-12 pt-60 col-sm-12 footer-widget md-mb-50">
              <h3 className="widget-title">Address</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-location"></i>
                  <div className="desc">
                  6th Floor, Saukhyanand CHS, Near Jalaram Mandir, Nehru Road, Dombivli East,
                  421201, Maharashtra State, India
                  </div>
                </li>
                <li>
                  <i className="flaticon-call"></i>
                  <div className="desc">
                    <a href="tel:(+91)8879256181">+91 8879256181 ,+91 7045082211</a>
                  </div>
                </li>
                <li>
                  <i className="flaticon-email"></i>
                  <div className="desc">
                    <a href="mailto:swardharaapp@gmail.com">swardharaapp@gmail.com</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
